import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import RecommendedProducts from './cart/RecommendedProducts';
import { useModifyCart, useUpsellProducts } from '../hooks/cart';
import Slider from './CustomSlider';
import useSegment from '../hooks/segment';
import getCartType from '../helpers/getCartType';
import useAPI from '../NetworkCall/API';
import ProductTileInCart from './cart/ProductTileInCart';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSavedForLaterItemAddedOrRemoved } from '../redux/slices/wishlistSlice';
import { setAllCartProductsWidget } from '../redux/slices/cartSlice';

const sliderConfig = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
  arrows: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true
      }
    }
  ]
};

const MAX_SAVE_FOR_LATER_COUNT = 6;

const ProductsUserMayLike = ({ className = '', isLoading = false, forCheckout = false, showAllCartProductsWidget = false }) => {
  // const {
  //   // state: {
  //   //   wishlist: { saveForLaterItems = [],
  //   //     // isSavedForLaterItemAddedOrRemoved
  //   //   } = {},
  //   //   // cart: { cartData: { cart_items: cartItems = [] } = {} }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const saveForLaterItems = useSelector(state => state.wishlist.saveForLaterItems) || []
  const { cartData: { cart_items: cartItems = [] } = {} } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()
  const isSavedForLaterItemAddedOrRemoved = useSelector(state => state.wishlist.isSavedForLaterItemAddedOrRemoved)

  const inStockProducts = saveForLaterItems?.filter(item => !!item?.is_sold_out !== true);

  const [isSavedForLaterSelected, setIsSavedForLaterSelected] = useState(false);

  const { trackEvent } = useSegment();

  const { getSaveForLaterItems } = useAPI();

  const {
    data: { upsellProducts }
  } = useUpsellProducts();

  const router = useRouter();

  useEffect(() => {
    if (!inStockProducts?.length && !upsellProducts?.length) {
      setIsSavedForLaterSelected(null);
    }
    if (!inStockProducts?.length) {
      setIsSavedForLaterSelected(false);
    }
    if (!upsellProducts?.length) {
      setIsSavedForLaterSelected(true);
    }
  }, [upsellProducts, inStockProducts]);

  useEffect(() => {
    setTimeout(() => {
      getSaveForLaterItems();
    }, 3000)
  }, []);

  useEffect(async () => {
    if (isSavedForLaterItemAddedOrRemoved) {
      await getSaveForLaterItems();
    }

    // dispatch({ type: 'setIsSavedForLaterItemAddedOrRemoved', payload: false });
    dispatchRedux(setIsSavedForLaterItemAddedOrRemoved(false))
  }, [isSavedForLaterItemAddedOrRemoved]);

  const cartItemsExcludingBundles = cartItems.filter(item => !item.bundle_id);
  if (!inStockProducts?.length && !cartItemsExcludingBundles?.length) {
    return null;
  }

  const navigateToWishlist = () => {
    // close the cartwidget if Open and then navigate
    // dispatch({ type: 'setAllCartProductsWidget', payload: false });
    dispatchRedux(setAllCartProductsWidget(false))
    router.push('/wishlist/saved-for-later');
  };

  const saveForLaterClickHandler = () => {
    setIsSavedForLaterSelected(true);
    trackEvent('switch recommendation type', {
      destination: 'save-for-later',
      products: saveForLaterItems.slice(0, 5),
      scheduleType: getCartType(cartItems),
      page: window.location.pathname,
      source: window.location.pathname !== '/checkout' ? 'cartWidget' : 'checkout'
    }, true);
  };

  const renderHeading = () => {
    if (upsellProducts?.length) {
      return (
        <>
          <div className="recommend-prod-section">
            <div className="d-flex align-items-center recommend-prod-title">
              {/* <img src="/static/images/light-bulb.svg" alt="saved for later icon" /> */}
              <h6 className="mb-0 ">Recommending other Products</h6>
            </div>
            <div className="reco-products tabs-btn p-0">
              <button
                onClick={() => setIsSavedForLaterSelected(false)}
                className={` you-may-like ${isSavedForLaterSelected ? 'not active' : 'active-tab'}`}
                disabled={!upsellProducts?.length}
              >
                You may Like
              </button>
              <button
                disabled={!inStockProducts?.length}
                onClick={saveForLaterClickHandler}
                className={` saved-for-later ml-2 ${isSavedForLaterSelected ? 'active-tab' : ''}`}
              >
                Saved for later
              </button>
            </div>
          </div>
        </>
      );
    }
    if (inStockProducts?.length) {
      return (
        <div className="d-flex align-items-center">
          <label className="mini-label">Saved For Later Products</label>
        </div>
      );
    }
    return null;
  };
  if (!upsellProducts.length && !inStockProducts.length) return null
  return (
    <div className={`padding-15-side mt-3 ${className}`}>
      {renderHeading()}

      <div className=" ">
        {isSavedForLaterSelected && inStockProducts?.length ? (
          <div className="recommended-products  one-rem-mt cart-widget-reco-products">
            <Slider {...sliderConfig}>
              {inStockProducts?.slice(0, 6)?.map((element, index) => {
                return (
                  <ProductTileInCart
                    key={`saveForLater${index}`}
                    element={element}
                    categoryType="Save For Later Items"
                    cartItems={cartItems}
                    productIndex={index}
                  />
                );
              })}
              {inStockProducts?.length > MAX_SAVE_FOR_LATER_COUNT ? (
                <div className="p-item relative r-adjust-padding cursor-pointer see-all-wishlist">
                  <h6 className="text-center">See all saved products</h6>
                  <button className="btn btn-primary view-btn bold px-3" onClick={navigateToWishlist}>
                    View
                  </button>
                </div>
              ) : null}
            </Slider>
          </div>
        ) : null}
        <RecommendedProducts
          className="two-rem-mt cart-widget-reco-products"
          isLoading={isLoading}
          forCheckout={forCheckout}
          showUpsellProducts={!isSavedForLaterSelected}
          showAllCartProductsWidget={!isSavedForLaterSelected}
        />
      </div>
    </div>
  );
};

export default ProductsUserMayLike;
